<template>
  <main>
    <div
      class="drag-and-drop-upload"
      @dragenter="highlight = true"
      @dragleave="highlight = false"
      @dragover.stop.prevent="highlight = true"
      @drop.stop.prevent="handleDrop($event)"
      @click="isHandleDropModalOpen = true"
    >
      <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
        <rect
          width="100%"
          height="100%"
          fill="none"
          rx="10"
          ry="10"
          stroke="#004d94"
          stroke-width="3"
          stroke-dasharray="10 6"
          stroke-dashoffset="0"
        />
      </svg>
      <div>
        <div class="folder">
          <img src="../assets/img/sidepanel/upload-s1.svg" />
          <img class="plus" src="../assets/img/sidepanel/upload-s2.svg" />
          <img class="doc" src="../assets/img/sidepanel/upload-s-1.svg" />
        </div>
        <p class="desktop-text">ЗАВЛАЧЕТЕ ИЛИ <span>КАЧЕТЕ</span> ТУК ДОКУМЕНТ</p>
        <p class="mobile-text"><span>КАЧЕТЕ</span> ДОКУМЕНТ</p>
      </div>
    </div>
    <CreateDocumentModal
      v-if="isHandleDropModalOpen"
      @close="(isHandleDropModalOpen = false), (files = [])"
      :files="files"
    />
  </main>
</template>

<script>
import CreateDocumentModal from '@/views/home/CreateDocumentModal.vue';
export default {
  components: {
    CreateDocumentModal,
  },
  data() {
    return {
      files: [],
      isHandleDropModalOpen: false,
    };
  },
  methods: {
    handleDrop(e) {
      this.highlight = false;
      const dt = e.dataTransfer;
      const files = dt.files;

      this.handleFiles(files);
    },
    handleFiles(files) {
      this.files = [];
      for (let f of files) {
        this.files.push(f);
        this.isHandleDropModalOpen = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.drag-and-drop-upload {
  border-radius: 10px;
  box-shadow: 0px 0px 88px #00000029;
  cursor: pointer;
  margin-top: 1rem;
  min-height: 140px;
  overflow: hidden;
  position: relative;

  > svg {
    position: absolute;
    width: 100%;
    height: 100%;

    rect {
      @include transition($transition);
    }
  }

  > div {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    > p {
      color: #619ed6;
      font-size: 1rem;
      font-weight: 600;
      margin-top: 30px;
      text-align: center;
      span {
        color: #004d94;
        text-decoration: underline;
      }
    }
  }

  .folder {
    display: inline;
    position: relative;
    @include transition($transition);

    .plus {
      bottom: -10%;
      position: absolute;
      right: -10%;
      @include transition($transition);
    }
    .doc {
      bottom: -60%;
      opacity: 0;
      position: absolute;
      right: -35%;
      @include transform(translateX(100%) translateY(100%));
      @include transition($transition);
    }
  }

  &:hover {
    > svg {
      rect {
        stroke-dasharray: 15, 6;
      }
    }

    .folder {
      @include transform(scale(1.2));
      .plus {
        opacity: 0;
        @include transform(translateX(100%) translateY(100%));
      }
      .doc {
        opacity: 1;
        @include transform(initial);
      }
    }
  }
  .mobile-text {
    display: none;
  }
}
@media screen and (max-width: $s), (orientation: landscape) and (max-width: $landscape) {
  .drag-and-drop-upload {
    align-items: center;
    display: flex;
    min-height: fit-content;
    margin-top: 0;

    > div {
      align-items: center;
      flex-direction: row;
      gap: 10px;

      > p {
        margin-top: 0;
      }
    }
    .desktop-text {
      display: none;
    }
    .mobile-text {
      display: block;
    }
  }
}
</style>